import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactForm from "../components/ContactForm"
import PostPreview from "../components/PostPreview"
import SubPageHeader from "../components/SubPageHeader"
import {graphql} from 'gatsby'

class IndexPage extends React.Component {
    render() {
        const items = [];
        const posts = this.props.data.allMarkdownRemark.edges;
        posts.forEach((post) => {
            items.push(<PostPreview data={post} key={post.node.frontmatter.path}/>);
        });

        return (
            <Layout>
                <SEO title="Ideen priorisieren" description="Welche Ideen bringen meinen Anwendern den größten Nutzen? Bewährte Methoden zur Priorisierung von Ideen findet man hier."/>
                <div className="SubPage-wrapper">
                    <SubPageHeader
                        subPageTitle="Ideen priorisieren"
                        subPageDescription={<React.Fragment>
                            <p>„Wenn man guten Gebrauch von seiner Zeit machen will, muss man wissen, was am wichtigsten ist, und sich dann mit ganzer Kraft dafür einsetzen.“
                                – Lee Iacocca, amerikanischer Topmanager
                            </p>
                            <p>
                                Ich habe noch kein Projekt erlebt, in dem nur genauso viele Ideen für die Umsetzung der Anforderungen im Raum standen, wie Ressourcen verfügbar waren. Wie könnt Ihr also erkennen, welche Ideen den Anwendern den größten Nutzen bringen und in welcher Reihenfolge Ihr sie am besten umsetzt?<br/>
                                Im Folgenden beschreibe ich Euch einige Methoden, die sich hierfür bewährt haben.
                            </p>
                        </React.Fragment>}
                    />

                    <div className="IndexPosts-container">
                        {items}
                    </div>
                </div>
                <ContactForm/>
            </Layout>
        );
    }
}

export default IndexPage;

export const pageQuery = graphql`
query PrioQuery {
  allMarkdownRemark(filter: {frontmatter: {category: {eq: "priorisierung"}}}, sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        timeToRead
        frontmatter {
          description
          date
          category
          image {
            childImageSharp {
                resize(quality: 100, width: 800) {
                    src
                }
            }
          }
          path
          title
        }
      }
    }
  }
}
`;
